import { render, staticRenderFns } from "./CheckboxSection.vue?vue&type=template&id=73032a18&scoped=true&"
import script from "./CheckboxSection.vue?vue&type=script&lang=js&"
export * from "./CheckboxSection.vue?vue&type=script&lang=js&"
import style0 from "@lazy/client/details/lock/_checkbox-section.scss?vue&type=style&index=0&id=73032a18&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73032a18",
  null
  
)

export default component.exports