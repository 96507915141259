<template>
  <b-row
    v-if="hasEnzoCardAccess"
  >
    <b-col
      cols="12"
    >
      <div class="d-flex">
        <vs-checkbox
          v-model="lock.types"
          :vs-value="section.value"
          color="#0B2772"
        />
        <label class="checkbox-label mt-2">
          {{ $t(section.label) }}
        </label>
      </div>
      <div class="d-flex">
        <p class="checkbox-description ml-1">
          {{ $t(section.description) }}
        </p>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import {CHECKBOX_SECTION} from "@/classes/doinsport/Lock";

export default {
  name: CHECKBOX_SECTION,
  props: {
    section: {
      type: Object,
      default: () => {
      }
    },
    lock: {
      type: Object,
      default: () => {
      }
    },
  },
  computed: {
    hasEnzoCardAccess() {
      if (this.section.label === 'views.client.lock-establishment-access') {
        return null !== this.$store.getters['auth/currentClub'].accessControlService;
      }

      return true;
    },
  }
}
</script>
<style lang="scss" src="@lazy/client/details/lock/_checkbox-section.scss" scoped/>
